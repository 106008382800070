import React from "react";
import PropTypes from "prop-types";
import Card from './card';
import './card.css';

const FeatureCard = ({ children, label = '', description = '', className = '', labelClassName = '', style = {}, cardStyle = {} }) => {
  const featureCardStyle = {
    textAlign: 'center',
    padding: '30px 12px',
    position: 'relative',
    minHeight: '150px',
    width: '100%',
    ...style,
  };

  const labelStyle = {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#1F2933',
    marginBottom: description.length > 0 ? '12px' : '0',
  };

  const descriptionStyle = {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#1F2933',
  };

  return (
    <Card className={className} style={cardStyle}>
      <div style={featureCardStyle}>
        <div>{children}</div>
        <p className={`label ${labelClassName}`} style={labelStyle}>{label}</p>
        {description.length > 0 && (<span style={descriptionStyle}>{description}</span>)}
      </div>
    </Card>
  )
}

FeatureCard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FeatureCard
