import React from 'react';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'reactstrap';
import Section from '../../components/section';
import Button from '../../components/button';

const claimStyle = {
  marginBottom: '48px',
};

const SolutionSection = () => (
  <Section title="Komfort">
    <Container>
      <Row>
        <Col>
          <p className="content" style={claimStyle}>
            Das moderne und allergikerfreundliche Nichtraucher-Appartment verfügt über kostenfreies W-LAN/Telefon und hat eine voll ausgestattete Küche mit Geschirrspüler, Kaffee-/Kaffeepad-Maschine (Senceo), Wasserkocher, Toaster, Tiefkühlfach und Backofen.<br />
            Im gemütlichen Wohnbereich steht Ihnen ein Elektrokamin, eine Hifi-Anlage, ein Flachbild-TV und ein Safe zur Verfügung.<br />
            <br />
            Im komfortablen Badezimmer befindet sich eine eigene Sauna zur vollkommenen Entspannung in privater Atmosphäre.<br />
            Die Böden sind mit hochwertigem Parkett bzw. Fliesen ausgestattet. Haustiere finden bei uns leider keinen Platz.
            Von der ruhig gelegenen Terrasse mit Gartenmöbeln und eigenem Strandkorb hat man einen wunderschönen Blick ins Grüne.<br />
          </p>

          <img
            src="https://res.cloudinary.com/dwygmgogv/image/upload/v1388060726/011_Haus_11_Terrasse_3_wnrnmw.jpg"
            style={{ width: '100%' }}
            alt="Auszeit am Meer in Wremen"
          />

          <p className="text-center">
            <div className="text-center">
              <Link to="/ausstattung"><Button primary>Mehr erfahren</Button></Link>
            </div>
          </p>
        </Col>
      </Row>
    </Container>
  </Section>
);

export default SolutionSection
