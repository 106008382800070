import React from "react";
import PropTypes from "prop-types";
import "./button.css";

const Button = ({ children, disabled = false, loading = false, primary = false, onPress = () => {}, style = {} }) => {
  const labelStyle = {
    align: 'center',
    backgroundColor: primary ? '#62b7f0' : '#FFF',
    color: primary ? '#FFF' : '#62b7f0',
    display: 'table-cell',
    fontSize: '16px',
    fontWeight: 'bold',
    textDecoration: 'none',
  };

  return (
    <div style={style}>
      <div
        className={primary ? 'button button-primary' : 'button button-default'}
        onClick={() => {
          if (!disabled) {
            onPress();
          }
        }}
        onTouchStart={() => {
          if (!disabled) {
            onPress();
          }
        }}
      >
        <span style={labelStyle}>
          {loading ? 'Loading...' : children}
        </span>
      </div>
    </div>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Button
