import React from "react";
import PropTypes from "prop-types";

const Card = ({ children, className = '', style = {} }) => {
  const cardStyle = {
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(31, 41, 51, 0.24), 0px 2px 4px rgba(31, 41, 51, 0.12)',
    borderRadius: '4px',
    marginBottom: '32px',
    ...style,
  }

  return (
    <div className={className} style={cardStyle}>{children}</div>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Card
