import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Section from '../../components/section';
import './02_problem.css';

const claimStyle = {
  padding: '20px 0',
  marginBottom: '48px',
};

const ProblemSolutionSection = () => (
  <Section title="Top Lage" style={{ paddingTop: '100px' }}>
    <Container>
      <Row>
        <Col>
          <p className="content" style={claimStyle}>
            Direkt am Nordseedeich, ca. 150 Meter von der Nordsee entfernt, liegt unsere 66 qm große hochwertige Ferienwohnung im „Resort Deichgraf“.<br />
            Inmitten des einzigartigen Weltnaturerbes Wattenmeer und ca. 900 m vom Ortszentrum entfernt, befindet sich unsere geschmackvoll eingerichtete Erdgeschosswohnung mit Süd-Westterrasse und Ausblick auf Wiesen und Nordseedeich.<br />
            In wenigen Gehminuten sind der idyllische kleine Hafen, der Grünstrand und der Leuchtturm „Kleiner Preuße“ - das Wahrzeichen Wremens - erreicht. In der nahen Umgebung finden Sie Einkaufsmöglichkeiten und Restaurants.<br />
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <iframe
            width="100%"
            height="720"
            src="https://www.youtube.com/embed/Stgrv_70NIY"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
        </Col>
      </Row>
    </Container>
  </Section>
)

export default ProblemSolutionSection
