import React from "react"
import { Col, Container, Row } from 'reactstrap';
import Section from "../../components/section";
import './04_why-now.css';
import Button from '../../components/button';
import { Link } from 'gatsby';

const claimStyle = {
  marginBottom: '48px',
};

const WhyNowSection = () => (
  <Section title="Wremen" className="whynow__container">
    <Container>
      <Row>
        <Col>
          <p className="content" style={claimStyle}>
            Das malerische Nordseebad Wremen liegt zwischen Bremerhaven und Cuxhaven und ist der älteste Badeort an der Wurster Seeküste. Direkt neben dem Resort Deichgraf befindet sich das Gästezentrum. Nicht weit entfernt liegt der neu gestaltete Kutterhafen, in dem die schmackhaften Krabben angelandet werden. Genießen Sie die allergiearme Nordseeluft am Weltnaturerbe Wattenmeer bei Spaziergängen am Deich oder auf gut ausgebauten Fahrradwegen. Unternehmen Sie Ausflüge nach Bremerhaven ins Klimahaus, in den Zoo am Meer, ins Schifffahrtsmuseum oder ins Auswandererhaus und das angrenzende Einkaufszentrum „Mediterraneo“. In Cuxhaven laden die historischen Gebäude  des alten Fischereihafens zum Bummeln ein. Von Cuxhaven kann man zu Fuß oder mit der Pferdekutsche durch das Watt zur Insel Neuwerk gelangen. Ein Tagesausflug zur Hochseeinsel Helgoland ist sowohl von Bremerhaven als auch von Cuxhaven möglich.
          </p>

          <p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d695422.5367812612!2d7.955312791848898!3d53.562132251664124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b6a5748d3cf41b%3A0x1adeb160e336ee92!2sWremen%2C%2027638%20Wurster%20Nordseek%C3%BCste!5e0!3m2!1sde!2sde!4v1589024871172!5m2!1sde!2sde"
              width="100%"
              height="450"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0">
            </iframe>
          </p>
          <p style={{ textAlign: 'center' }}>
            <a href="https://goo.gl/maps/vA1ajwsiWdAHkb5u7" target="_blank"><Button>Google Maps öffnen</Button></a>
          </p>
        </Col>
      </Row>
    </Container>
  </Section>
);

export default WhyNowSection
