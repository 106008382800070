import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnchor, faUmbrellaBeach, faBars, faCouch, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';

import Button from '../../components/button';
import FeatureCard from '../../components/cards/featureCard';
import SplashScreen from '../../components/images/splash-screen';
import LogoIcon from '../../components/images/logo-autobahn-network-fff';
import '../01_intro.css';

const background = {
  backgroundColor: '#62b7f0',
  top: '0',
  bottom: '137px',
  minHeight: '460px',
  opacity: 0.84,
  position: 'absolute',
  width: '100%',
};

const containerStyle = {
  backgroundColor: '#FFF',
  height: '100vh',
  width: '100%',
};

const contentContainerStyle = {
  color: 'white',
};

const navbarContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  height: '100px',
  padding: '24px',
  position: 'relative',
  textAlign: 'right',
  top: '0',
  margin: '0% auto',
  maxWidth: '920px',
  width: '100%',
  zIndex: 10,
};

const navbarLinkContainerStyle = {
  lineHeight: '48px',
};

const linkStyle = {
  color: '#ffffff',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: 'bold',
  padding: '0 12px',
  textDecoration: 'none',
  marginRight: '12px',
};

const mobileNavStyle = {
  paddingBottom: '36px',
  position: 'absolute',
  top: '24px',
  left: '24px',
};

const mobileNavToggleStyle = {
  position: 'absolute',
  top: '24px',
  right: '24px',
};

const IntroSection = () => {
  const [mobileNavVisible, setMobileNavVisible] = useState(false);

  const toggleMobileNavVisibility = () => {
    setMobileNavVisible(!mobileNavVisible);
  };

  return (
    <div style={containerStyle}>
      <SplashScreen />
      <div className="intro__background" />
      <div className="d-sm-none text-center pt-4">
        {!mobileNavVisible && false && (<LogoIcon />)}
        {mobileNavVisible && (
          <div style={mobileNavStyle}>
            <Link to="/testnet"><Button style={{ display: 'inline-block' }}>Testnet</Button></Link>
          </div>
        )}
        <div style={mobileNavToggleStyle} onClick={toggleMobileNavVisibility}>
          <FontAwesomeIcon color="#FFFFFF" icon={faBars} size="2x" />
        </div>
      </div>
      <div className="d-none d-sm-flex" style={navbarContainerStyle}>
        <div>
          {false && (<LogoIcon />)}
        </div>
        <div style={navbarLinkContainerStyle}>
          <Link className="d-none d-md-inline-block" to="/ausstattung" style={linkStyle}>Ausstattung</Link>
          <Link to="/reservieren"><Button style={{ display: 'inline-block' }}>Reservieren</Button></Link>
        </div>
      </div>
      <div className="intro__content-container">
        <Container style={{ maxWidth: '920px' }}>
          <Row>
            <Col>
              <div style={contentContainerStyle}>
                <h1 className="intro__title">
                  Erholung pur
                </h1>
                <h2 className="intro__slogan">Auszeit in Wremen</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="intro__features-container">
        <Container style={{ maxWidth: '920px' }}>
          <Row>
            <Col xs={6} sm={6} md={3}>
              <FeatureCard label="Top Lage">
                <FontAwesomeIcon color="#9aa4b3" icon={faMapMarkedAlt} style={{ height: '80px' }} size="3x" />
              </FeatureCard>
            </Col>
            <Col xs={6} sm={6} md={3}>
              <FeatureCard label="Küste & Meer">
                <FontAwesomeIcon color="#9aa4b3" icon={faAnchor} style={{ height: '80px' }} size="3x" />
              </FeatureCard>
            </Col>
            <Col xs={6} sm={6} md={3}>
              <FeatureCard label="Komfort">
                <FontAwesomeIcon color="#9aa4b3" icon={faCouch} style={{ height: '80px' }} size="3x" />
              </FeatureCard>
            </Col>
            <Col xs={6} sm={6} md={3}>
              <FeatureCard label="Erholung">
                <FontAwesomeIcon color="#9aa4b3" icon={faUmbrellaBeach} style={{ height: '80px' }} size="3x" />
              </FeatureCard>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
};

export default IntroSection
