import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import IntroSection from "../sections/landingpage/01_intro"
import ProblemSolutionSection from "../sections/landingpage/02_problem"
import SolutionSection from "../sections/landingpage/03_solution"
import WhyNowSection from "../sections/landingpage/04_why-now"
import FeaturesWhySection from "../sections/landingpage/06_whitepaper"

const smallScreensSpacerStyle = {
    height: '120px',
};

const IndexPage = () => (
  <Layout headerHidden fluidContainer>
    <SEO />

    <IntroSection />
    <div className="d-block d-md-none" style={smallScreensSpacerStyle} />
    <ProblemSolutionSection />
    <SolutionSection />
    <WhyNowSection />
    <FeaturesWhySection />
  </Layout>
)

export default IndexPage
