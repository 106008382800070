import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const LogoAutobahnNetworkWhite = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo-autobahn-network.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imageStyle = {
    display: 'inline-block',
    height: '80px',
    width: '91px',
    filter: 'brightness(0) invert(1)',
    zIndex: '900',
  };

  return <Img
    fluid={data.placeholderImage.childImageSharp.fluid}
    style={imageStyle}
  />
}

export default LogoAutobahnNetworkWhite
