import React from 'react';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'reactstrap';
import Section from '../../components/section';
import Button from '../../components/button';

const whitepaperStyle = {
  backgroundColor: '#F5F7FA',
  borderRadius: '4px',
  padding: '48px',
  textAlign: 'center',
};

const FeaturesWhySection = () => (
  <Section title="Reservieren">
    <Container fluid="lg">
      <Row>
        <Col md={12}>
          <p className="content" style={whitepaperStyle}>
            <img src="/images/icons/icon-whitepaper.svg" style={{ height: '320px' }} alt="Autobahn Network Whitepaper" />
            <Link to="/reservieren"><Button>Verfügbarkeit</Button></Link>
          </p>
        </Col>
      </Row>
    </Container>
  </Section>
)

export default FeaturesWhySection
